import { getUniqueId } from '@/utils/misc'

import { sendTrackerGA } from '../gaTracker'
import { sendTrackerSatuEvent } from '../satuTracker'
import {
  TrackerPageIndentifier,
  TrackerSectionType,
  TrackerSubSectionType,
} from '../shared'
import { GAEventName } from '../shared'

export const trackViewPage = ({
  gaEventName,
  pageTitle,
}: {
  gaEventName: GAEventName
  pageTitle: TrackerPageIndentifier
}) => {
  const trackerId = getUniqueId()
  sendTrackerGA({
    id: trackerId,
    eventName: gaEventName,
    eventAction: 'PAGE_VIEWED',
    eventParams: {},
  })

  sendTrackerSatuEvent({
    id: trackerId,
    pageTitle: pageTitle,
    activityType: 'PAGE_VIEWED',
    eventParams: {},
  })
}

export const trackClickLinkFaq = ({
  pageTitle,
  gaEventName,
  eventParams = {},
}: {
  pageTitle: TrackerPageIndentifier
  gaEventName: GAEventName
  eventParams?: {
    section?: TrackerSectionType
    sub_section?: TrackerSubSectionType
  }
}) => {
  const trackerId = getUniqueId()
  let eventLabel = ''
  if (eventParams.section && eventParams.sub_section) {
    eventLabel = `${eventParams.section} - ${eventParams.sub_section}`
  } else if (eventParams.section) {
    eventLabel = eventParams.section
  }

  const eventParamsGa = eventLabel
    ? {
        eventLabel,
      }
    : {}

  sendTrackerGA({
    id: trackerId,
    eventName: gaEventName,
    eventAction: 'FAQ_LINK_CLICKED',
    eventParams: eventParamsGa,
  })

  sendTrackerSatuEvent({
    id: trackerId,
    pageTitle: pageTitle,
    activityType: 'FAQ_LINK_CLICKED',
    eventParams,
  })
}

const trackClickSurveyCloseButton = ({
  pageTitle,
  gaEventName,
}: {
  pageTitle: TrackerPageIndentifier
  gaEventName: GAEventName
}) => {
  const trackerId = getUniqueId()
  sendTrackerGA({
    id: trackerId,
    eventName: gaEventName,
    eventAction: 'SURVEY_CLOSE_BUTTON_CLICKED',
  })
  sendTrackerSatuEvent({
    id: trackerId,
    pageTitle: pageTitle,
    activityType: 'SURVEY_CLOSE_BUTTON_CLICKED',
    eventParams: {},
  })
}

export const trackClickSurveyCloseButton_Publikasi = () => {
  trackClickSurveyCloseButton({
    pageTitle: 'Publikasi',
    gaEventName: 'publikasi',
  })
}

export const trackClickSurveyCloseButton_Dataset = () => {
  trackClickSurveyCloseButton({
    pageTitle: 'Dataset',
    gaEventName: 'dataset',
  })
}

export const trackClickSurveyCloseButton_DataInduk = () => {
  trackClickSurveyCloseButton({
    pageTitle: 'Data Induk',
    gaEventName: 'induk',
  })
}

export const trackClickSurveyCloseButton_PlatformPrioritas = () => {
  trackClickSurveyCloseButton({
    pageTitle: 'Platform Prioritas',
    gaEventName: 'prioritas',
  })
}

const trackClickSurveySubmitButton = ({
  pageTitle,
  gaEventName,
  nilai,
  sektor,
  tujuan,
  sektor_lain,
  tujuan_lain,
  saran,
}: {
  pageTitle: TrackerPageIndentifier
  gaEventName: GAEventName
  nilai: string
  sektor: string
  tujuan: string
  sektor_lain: string
  tujuan_lain: string
  saran: string
}) => {
  const trackerId = getUniqueId()
  sendTrackerGA({
    id: trackerId,
    eventName: gaEventName,
    eventAction: 'SURVEY_SUBMIT_BUTTON_CLICKED',
    eventParams: {
      eventLabel: `${nilai} - ${sektor} - ${tujuan} - ${sektor_lain || 'nil'} - ${tujuan_lain || 'nil'} - ${saran}`,
    },
  })

  sendTrackerSatuEvent({
    id: trackerId,
    pageTitle: pageTitle,
    activityType: 'SURVEY_SUBMIT_BUTTON_CLICKED',
    eventParams: {
      nilai,
      sektor,
      tujuan,
      sektor_lain,
      tujuan_lain,
      saran,
    },
  })
}

export const trackClickSurveySubmitButton_Publikasi = ({
  nilai,
  sektor,
  tujuan,
  sektor_lain,
  tujuan_lain,
  saran,
}: {
  nilai: string
  sektor: string
  tujuan: string
  sektor_lain: string
  tujuan_lain: string
  saran: string
}) => {
  trackClickSurveySubmitButton({
    pageTitle: 'Publikasi',
    gaEventName: 'publikasi',
    nilai,
    sektor,
    tujuan,
    sektor_lain,
    tujuan_lain,
    saran,
  })
}

export const trackClickSurveySubmitButton_Dataset = ({
  nilai,
  sektor,
  tujuan,
  sektor_lain,
  tujuan_lain,
  saran,
}: {
  nilai: string
  sektor: string
  tujuan: string
  sektor_lain: string
  tujuan_lain: string
  saran: string
}) => {
  trackClickSurveySubmitButton({
    pageTitle: 'Dataset',
    gaEventName: 'dataset',
    nilai,
    sektor,
    tujuan,
    sektor_lain,
    tujuan_lain,
    saran,
  })
}

export const trackClickSurveySubmitButton_DataInduk = ({
  nilai,
  sektor,
  tujuan,
  sektor_lain,
  tujuan_lain,
  saran,
}: {
  nilai: string
  sektor: string
  tujuan: string
  sektor_lain: string
  tujuan_lain: string
  saran: string
}) => {
  trackClickSurveySubmitButton({
    pageTitle: 'Data Induk',
    gaEventName: 'induk',
    nilai,
    sektor,
    tujuan,
    sektor_lain,
    tujuan_lain,
    saran,
  })
}

export const trackClickSurveySubmitButton_PlatformPrioritas = ({
  nilai,
  sektor,
  tujuan,
  sektor_lain,
  tujuan_lain,
  saran,
}: {
  nilai: string
  sektor: string
  tujuan: string
  sektor_lain: string
  tujuan_lain: string
  saran: string
}) => {
  trackClickSurveySubmitButton({
    pageTitle: 'Platform Prioritas',
    gaEventName: 'prioritas',
    nilai,
    sektor,
    tujuan,
    sektor_lain,
    tujuan_lain,
    saran,
  })
}

const trackClickOpenSurvey = ({
  pageTitle,
  gaEventName,
}: {
  pageTitle: TrackerPageIndentifier
  gaEventName: GAEventName
}) => {
  const trackerId = getUniqueId()
  sendTrackerGA({
    id: trackerId,
    eventName: gaEventName,
    eventAction: 'SURVEY_VIEWED',
  })
  sendTrackerSatuEvent({
    id: trackerId,
    pageTitle: pageTitle,
    activityType: 'SURVEY_VIEWED',
    eventParams: {},
  })
}

export const trackClickOpenSurvey_Publikasi = () => {
  trackClickOpenSurvey({
    pageTitle: 'Publikasi',
    gaEventName: 'publikasi',
  })
}

export const trackClickOpenSurvey_Dataset = () => {
  trackClickOpenSurvey({
    pageTitle: 'Dataset',
    gaEventName: 'dataset',
  })
}

export const trackClickOpenSurvey_DataInduk = () => {
  trackClickOpenSurvey({
    pageTitle: 'Data Induk',
    gaEventName: 'induk',
  })
}

export const trackClickOpenSurvey_PlatformPrioritas = () => {
  trackClickOpenSurvey({
    pageTitle: 'Platform Prioritas',
    gaEventName: 'prioritas',
  })
}
