import { GATrackEventArgs, getDefaultTrackerData } from './shared'

export const sendTrackerGA = ({
  id,
  eventName,
  eventAction,
  eventParams = {},
}: GATrackEventArgs) => {
  if (typeof gtag !== 'undefined') {
    const params = {
      id,
      ...getDefaultTrackerData(),
      ...eventParams,
    }

    gtag('event', eventName, {
      eventAction,
      ...params,
    })
  }
}
