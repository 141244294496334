export const DatasetIcon = ({ width = 64, height = 65 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 64 65"
      fill="none"
    >
      <path
        d="M60.5257 19.3795V19.4328L58.5523 35.0671L55.8857 56.2633C55.8628 56.4614 55.8171 56.6519 55.7562 56.8347C55.7333 56.9033 55.6952 56.9643 55.6647 57.0405C55.619 57.1395 55.5809 57.2462 55.5276 57.3452C55.4819 57.4214 55.4285 57.4976 55.3752 57.5662C55.3219 57.65 55.2685 57.7262 55.2152 57.7947C55.1466 57.8709 55.0781 57.9395 55.0095 58.0081C54.9485 58.069 54.8876 58.13 54.819 58.1833C54.7428 58.2443 54.659 58.2976 54.5752 58.3586C54.5066 58.4043 54.4381 58.45 54.3695 58.4881C54.2781 58.5338 54.1866 58.5795 54.0952 58.6176C54.019 58.6481 53.9352 58.6862 53.859 58.709C53.76 58.7395 53.6685 58.7624 53.5695 58.7852C53.4857 58.8081 53.3942 58.8233 53.3028 58.8309C53.2038 58.8462 53.1123 58.8538 53.0133 58.8538C52.9676 58.8538 52.9219 58.8538 52.8762 58.8538C52.7695 58.8538 52.6628 58.8538 52.5562 58.8309L38.0723 57.0024L30.8266 58.6328L18.3238 61.4519C16.899 61.7643 15.4895 61.01 14.9409 59.73C15.619 60.2557 16.5181 60.4919 17.4247 60.2938L29.9276 57.4747L37.1733 55.8443L51.6571 57.6728C51.7638 57.6881 51.8704 57.6881 51.9771 57.6957C52.0228 57.6957 52.0685 57.6881 52.1143 57.6881C52.2133 57.6805 52.3047 57.6805 52.4038 57.6652C52.4952 57.6652 52.5866 57.6347 52.6704 57.6195C52.7695 57.5966 52.8609 57.5738 52.96 57.5433C53.0362 57.5128 53.12 57.4824 53.1962 57.4519C53.2876 57.4138 53.379 57.3681 53.4704 57.3224C53.539 57.2843 53.6076 57.2309 53.6838 57.1852C53.7676 57.1319 53.8438 57.0785 53.92 57.0176C53.9885 56.9566 54.0495 56.8957 54.1104 56.8347C54.179 56.7738 54.2476 56.7052 54.3162 56.629C54.3771 56.5605 54.4228 56.4766 54.4762 56.4005C54.5295 56.3243 54.5828 56.2557 54.6285 56.1795C54.6819 56.0805 54.7276 55.9738 54.7657 55.8671C54.7962 55.7985 54.8343 55.7376 54.8571 55.6614C54.9181 55.4862 54.9638 55.2957 54.9866 55.0976L57.6533 33.9014L59.6266 18.2671H59.6343L60.4876 19.3643H60.5257V19.3795Z"
        fill="#CECECE"
      />
      <path
        d="M46.3467 55.1281L34.4229 57.8024L29.821 58.8386L16.6172 61.8176C14.9334 62.191 13.2496 61.1319 12.8686 59.4481L12.2439 56.6671L4.17529 20.789L2.91053 15.1814C2.53719 13.4824 3.59624 11.7986 5.29529 11.4252L11.5886 10.0081L24.0991 7.20428L28.9982 6.09952H29.0134L40.1448 13.1319L43.7867 29.3757L48.7391 51.3795C49.1201 53.0633 48.0534 54.7548 46.3543 55.1281H46.3467Z"
        fill="#5CCBF2"
      />
      <path
        d="M46.3467 55.1281L34.4229 57.8024L32.541 57.5662L21.0667 56.1262C19.3525 55.9052 18.1334 54.3357 18.3467 52.629L18.3925 52.2481L18.7048 49.7947L23.2991 13.3147L24.0153 7.60808C24.0306 7.47094 24.061 7.32617 24.0991 7.19665L28.9982 6.09189H29.0134L40.1448 13.1243L43.7867 29.3681L48.7391 51.3719C49.1201 53.0557 48.0534 54.7471 46.3544 55.1205L46.3467 55.1281Z"
        fill="#1FA8E5"
      />
      <path
        d="M40.1449 13.1471L43.7868 29.3757L48.7316 51.3795C49.0821 52.949 48.183 54.5338 46.6744 55.0366C46.5678 55.0671 46.4611 55.1052 46.3544 55.1281L29.8211 58.8386L16.6173 61.8176C14.9259 62.1909 13.2497 61.1319 12.8687 59.4481L12.7849 59.0824C22.6668 53.9471 29.1049 47.7071 34.004 41.3757C38.903 35.0519 35.5963 14.1681 35.5963 14.1681L40.1373 13.1471H40.1449Z"
        fill="#1FA8E5"
      />
      <path
        d="M40.1447 13.1471L43.7866 29.3757L48.7314 51.3795C49.0819 52.949 48.1828 54.5338 46.6743 55.0366L27.8933 59.2728C27.8933 59.2728 38.4228 47.5852 41.2724 43.2805C44.1219 38.9757 38.1105 13.6043 38.1105 13.6043L40.1447 13.1471Z"
        fill="#0F7FB7"
      />
      <path
        d="M37.7809 27.326L13.6953 32.7362C12.7716 32.9437 12.1909 33.8608 12.3984 34.7845L12.4051 34.8143C12.6126 35.738 13.5297 36.3187 14.4534 36.1112L38.539 30.7009C39.4627 30.4934 40.0434 29.5764 39.8359 28.6526L39.8292 28.6229C39.6217 27.6991 38.7046 27.1185 37.7809 27.326Z"
        fill="#8131AA"
      />
      <path
        d="M22.9334 34.2138L20.602 34.7395L19.0172 35.0976L14.4458 36.1109C13.5239 36.3243 12.602 35.7376 12.3886 34.8081V34.7928C12.1829 33.8557 12.762 32.9414 13.6915 32.7357L17.722 31.829L21.0591 31.0824L22.1791 30.8309C23.101 30.6176 24.0229 31.2043 24.2286 32.1186V32.1414C24.442 33.0709 23.8553 33.9928 22.941 34.1986L22.9334 34.2138Z"
        fill="#581E96"
      />
      <path
        d="M31.2077 25.9167L25.021 27.3186L21.4324 28.1186L17.8058 28.9262C16.3429 29.2614 14.8953 28.3395 14.5601 26.869V26.8309C14.2248 25.3605 15.1467 23.9129 16.6096 23.5929L22.1639 22.3433L22.9715 22.1605L30.0115 20.5757C31.4667 20.2481 32.922 21.1624 33.2572 22.6176V22.6709C33.5848 24.1262 32.6629 25.5814 31.2077 25.909V25.9167Z"
        fill="#CE0000"
      />
      <path
        d="M39.2213 34.2626L15.1357 39.6728C14.212 39.8803 13.6313 40.7974 13.8388 41.7212L13.8455 41.7509C14.053 42.6747 14.9701 43.2553 15.8938 43.0478L39.9794 37.6375C40.9032 37.43 41.4838 36.513 41.2763 35.5892L41.2696 35.5595C41.0621 34.6357 40.145 34.0551 39.2213 34.2626Z"
        fill="#8131AA"
      />
      <path
        d="M24.3962 41.1395L21.661 41.749L19.6572 42.2062L15.901 43.0519C14.9791 43.2576 14.0572 42.6785 13.8515 41.7414V41.7262C13.6381 40.7966 14.2248 39.8747 15.1391 39.6766L20.1143 38.5643L20.3658 38.5033L23.6343 37.7643C24.5562 37.5586 25.4705 38.1376 25.6839 39.0519V39.09C25.8896 40.0119 25.3105 40.9338 24.3962 41.1395Z"
        fill="#581E96"
      />
      <path
        d="M30.3239 49.2995C30.1258 48.4005 29.7677 47.5852 29.2724 46.8614C28.2058 45.2843 26.5296 44.21 24.6553 43.8747C23.7486 43.6995 22.7962 43.7148 21.8439 43.9357C20.8991 44.149 20.0381 44.5452 19.2839 45.0786C17.021 46.6786 15.8248 49.5281 16.4724 52.4081C16.7772 53.7567 17.44 54.93 18.3467 55.8519C19.2077 56.7052 20.282 57.3224 21.4553 57.6652C21.4781 57.6728 21.5162 57.6881 21.5467 57.6881C22.6058 57.9776 23.7867 58.0233 24.9448 57.7643C25.7372 57.589 26.461 57.2843 27.1162 56.8881C28.2515 56.1871 29.1581 55.1814 29.7296 54.0157C30.4458 52.6062 30.6972 50.9528 30.3162 49.2995H30.3239ZM27.6115 52.4081C27.1086 53.7948 25.9353 54.8995 24.381 55.25C23.6039 55.4252 22.8191 55.3871 22.1181 55.1586C22.0953 55.1586 22.0724 55.1509 22.0648 55.1509C21.5162 54.9909 21.0058 54.709 20.5562 54.3586C19.802 53.7338 19.2381 52.8576 19.0096 51.8443C18.4686 49.4062 19.9848 47.0214 22.4077 46.4728C22.9639 46.3509 23.5353 46.3357 24.0762 46.4195C25.4629 46.6328 26.7048 47.4938 27.3677 48.7586C27.5505 49.0938 27.6877 49.4748 27.7715 49.8709C27.9696 50.7395 27.9086 51.6309 27.6115 52.4081Z"
        fill="#CC4495"
      />
      <path
        d="M30.3237 49.2995C30.1256 48.4005 29.7675 47.5852 29.2723 46.8614C28.2056 45.2843 26.5295 44.21 24.6552 43.8747C23.7485 43.6995 22.7961 43.7148 21.8437 43.9357C20.899 44.149 20.038 44.5452 19.2837 45.0786L18.6895 49.8024L18.3771 52.2557L18.3314 52.6367C18.118 54.3433 19.3371 55.9052 21.0514 56.1338L27.1085 56.8881C28.2437 56.1871 29.1504 55.1814 29.7218 54.0157C30.438 52.6062 30.6895 50.9528 30.3085 49.2995H30.3237ZM27.6114 52.4081C27.1085 53.7948 25.9352 54.8995 24.3809 55.25C23.6037 55.4252 22.819 55.3871 22.118 55.1586C22.0952 55.1586 22.0723 55.1509 22.0647 55.1509C21.5161 54.9909 21.0056 54.709 20.5561 54.3586C19.8018 53.7338 19.238 52.8576 19.0095 51.8443C18.4685 49.4062 19.9847 47.0214 22.4076 46.4728C22.9637 46.3509 23.5352 46.3357 24.0761 46.4195C25.4628 46.6328 26.7047 47.4938 27.3675 48.7586C27.5504 49.0938 27.6876 49.4748 27.7714 49.8709C27.9695 50.7395 27.9085 51.6309 27.6114 52.4081Z"
        fill="#C42079"
      />
      <path
        d="M30.3314 49.2995C30.7047 50.9528 30.4609 52.6062 29.7447 54.0233C28.8381 55.8595 27.1238 57.2843 24.96 57.7795C23.7942 58.0309 22.6133 57.9928 21.5542 57.7033C21.5162 57.7033 21.4857 57.6805 21.4552 57.6728C20.2819 57.3376 19.2076 56.7128 18.3542 55.8595C19.1162 55.3719 19.8552 54.869 20.5638 54.3662C21.0057 54.7167 21.5162 54.9986 22.0647 55.1586C22.0723 55.1586 22.0952 55.1662 22.1104 55.1738C22.8266 55.4024 23.5962 55.4405 24.3809 55.2652C25.9276 54.9148 27.1085 53.81 27.6038 52.4309C27.9009 51.6462 27.9695 50.7624 27.7714 49.8862C27.68 49.49 27.5428 49.1167 27.36 48.7814C28.0228 48.1567 28.6552 47.5243 29.2647 46.8843C29.7523 47.6005 30.1181 48.4233 30.3162 49.3224L30.3314 49.2995Z"
        fill="#C42079"
      />
      <path
        d="M30.339 49.2995C30.4762 49.9014 30.5219 50.5033 30.5066 51.09C30.4533 52.7662 29.7981 54.3586 28.7009 55.5852C28.4723 55.829 28.2285 56.0729 27.9619 56.2938C27.1162 57.0024 26.1028 57.5128 24.96 57.7719C23.7866 58.0462 22.6209 57.9929 21.5466 57.7033L21.9276 56.0348L22.1104 55.1738C22.3923 55.2576 22.6819 55.3186 22.979 55.3414C23.0171 55.349 23.0628 55.3567 23.1009 55.3567C23.139 55.3567 23.1619 55.3567 23.2 55.3567C23.3981 55.3643 23.6038 55.3567 23.8095 55.3414C24.0076 55.3262 24.2057 55.2957 24.3962 55.25C25.4323 55.0062 26.3009 54.4348 26.9181 53.65C27.1162 53.3986 27.299 53.1243 27.4438 52.8348C27.8781 51.9433 28.0228 50.8919 27.7866 49.8709C27.6952 49.4671 27.5581 49.0938 27.3752 48.7509C26.7123 47.4786 25.4704 46.6176 24.0838 46.4043L24.6704 43.8748C26.5371 44.2024 28.1981 45.2843 29.28 46.8614C29.7676 47.5776 30.1333 48.4005 30.339 49.2995Z"
        fill="#458CFF"
      />
      <path
        d="M30.339 49.2995C30.4762 49.9014 30.5219 50.5033 30.5066 51.09C30.4533 52.7662 29.7981 54.3586 28.7009 55.5852C28.4723 55.829 28.2285 56.0728 27.9619 56.2938C27.1162 57.0024 26.1028 57.5128 24.96 57.7719C23.7866 58.0462 22.6209 57.9928 21.5466 57.7033L21.9276 56.0347L22.1104 55.1738C22.3923 55.2576 22.6819 55.3186 22.979 55.3414C23.0171 55.349 23.0628 55.3567 23.1009 55.3567C23.139 55.3567 23.1619 55.3567 23.2 55.3567C23.3981 55.3643 23.6038 55.3567 23.8095 55.3414C24.0076 55.3262 24.2057 55.2957 24.3962 55.25C25.4323 55.0062 26.3009 54.4347 26.9181 53.65C27.1162 53.3986 27.299 53.1243 27.4438 52.8347C27.8781 51.9433 28.0228 50.8919 27.7866 49.8709C27.6952 49.4671 27.5581 49.0938 27.3752 48.7509C28.0381 48.1262 28.6704 47.4938 29.28 46.8538C29.7676 47.57 30.1333 48.3928 30.339 49.2919V49.2995Z"
        fill="#2560E0"
      />
      <path
        d="M28.7009 55.5852C28.4723 55.8291 28.2285 56.0729 27.9619 56.2938C27.1162 57.0024 26.1028 57.5129 24.96 57.7719C23.7866 58.0462 22.6209 57.9929 21.5466 57.7033L22.1181 55.1738C22.4 55.2576 22.6895 55.3186 22.9866 55.3414C23.0628 55.3491 23.1314 55.3567 23.2 55.3643C23.3981 55.3719 23.6038 55.3643 23.8095 55.3491C24.0076 55.3338 24.2057 55.3033 24.3962 55.2576C25.4323 55.0138 26.3009 54.4424 26.9181 53.6576L28.7009 55.5852Z"
        fill="#071A8C"
      />
      <path
        d="M41.6381 44.1033C41.7524 44.6062 41.4553 45.0862 40.9829 45.231L40.9143 45.2538L36.1143 46.3357L33.6534 46.8843C33.1429 46.9986 32.6248 46.6786 32.5181 46.1529C32.4039 45.65 32.701 45.17 33.1734 45.0252L33.2419 45.0024L38.0572 43.9129L40.5029 43.3719C41.0134 43.2652 41.5239 43.5852 41.6381 44.1033Z"
        fill="#2560E0"
      />
      <path
        d="M30.2172 16.8881L30.1486 16.9033L22.6362 18.5871L19.2077 19.3567L14.9029 20.3319C14.3772 20.4538 13.8667 20.1338 13.7601 19.6157C13.6458 19.1052 13.9429 18.6329 14.4229 18.4881L14.4839 18.4652L18.4991 17.5662L22.8877 16.5757L29.7296 15.0443C30.2477 14.9224 30.7505 15.25 30.8724 15.7681C30.9867 16.2557 30.6896 16.7433 30.2172 16.8881Z"
        fill="#2560E0"
      />
      <path
        d="M39.1798 49.1763L34.4296 50.2434C33.9123 50.3596 33.5867 50.8715 33.7025 51.3867C33.8182 51.902 34.3314 52.2255 34.8487 52.1093L39.5989 51.0422C40.1162 50.926 40.4417 50.4141 40.326 49.8989C40.2103 49.3836 39.6971 49.0601 39.1798 49.1763Z"
        fill="#2560E0"
      />
      <path
        d="M40.1449 13.1395L34.1258 14.5033C32.4268 14.8767 30.7506 13.81 30.362 12.1262L29.0134 6.09952L40.1525 13.1395H40.1449Z"
        fill="#2560E0"
      />
      <path
        d="M40.145 13.1471L43.7869 29.3757L48.7316 51.3795C49.0821 52.949 48.183 54.5338 46.6745 55.0366L39.025 56.7585C41.4554 55.8138 44.0764 48.5147 45.105 46.2443C46.1335 43.9662 39.0173 13.3909 39.0173 13.3909L40.145 13.1395V13.1471Z"
        fill="#00588E"
      />
      <path
        d="M11.5734 10.0081C9.9201 10.7319 8.63248 11.6919 7.63439 12.7433C6.71248 13.7186 6.03439 14.77 5.53915 15.7757C4.23629 18.4195 4.16772 20.789 4.16772 20.789L2.90296 15.1814C2.52963 13.4824 3.58867 11.7986 5.28772 11.4252L11.5734 10.0081Z"
        fill="#CAF5FF"
      />
      <path
        d="M36.0992 49.8633C35.482 50.5947 34.8497 51.3338 34.2401 52.0576C33.9735 51.9433 33.7601 51.6995 33.6916 51.3947C33.5773 50.8766 33.8973 50.3586 34.423 50.2443L36.0992 49.8633Z"
        fill="#0E43C1"
      />
      <path
        d="M11.3602 14.9224C11.7183 16.5071 10.7202 18.0843 9.12783 18.4424C7.92403 18.7166 6.73545 18.2138 6.0726 17.269C5.85164 16.9643 5.69164 16.6138 5.60784 16.2176C5.24974 14.6328 6.24022 13.0481 7.8326 12.69C8.22117 12.6062 8.60212 12.5985 8.97545 12.6595C10.1183 12.85 11.0935 13.7185 11.3678 14.9147L11.3602 14.9224Z"
        fill="#002066"
      />
      <path
        d="M7.63445 12.7509C6.71255 13.7262 6.03445 14.7776 5.53921 15.7833C5.44778 14.4119 6.2935 13.1471 7.63445 12.7509Z"
        fill="#00588E"
      />
      <path
        d="M8.93734 17.5738C8.80781 17.6043 8.69353 17.5814 8.57924 17.5128C8.46496 17.4443 8.39639 17.3452 8.37353 17.2157C8.34305 17.0862 8.36591 16.9719 8.43448 16.8576C8.50305 16.7433 8.6021 16.6748 8.73162 16.6519C8.86115 16.629 8.97543 16.6519 9.08972 16.7128C9.204 16.7814 9.27258 16.8805 9.30305 17.01C9.33353 17.1395 9.30305 17.2614 9.23448 17.3681C9.16591 17.4748 9.06686 17.5509 8.93734 17.5738ZM8.46496 13.4671L9.01353 16.2557L8.30496 16.4157L7.604 13.6576L8.46496 13.4671Z"
        fill="white"
      />
      <path
        d="M22.9335 34.2138L20.6021 34.7395L21.0592 31.09L22.1792 30.8386C23.1011 30.6252 24.023 31.2119 24.2287 32.1262V32.149C24.4421 33.0786 23.8554 34.0005 22.9411 34.2062L22.9335 34.2138Z"
        fill="#240872"
      />
      <path
        d="M25.6839 39.09C25.8896 40.0119 25.3106 40.9338 24.3963 41.1395L21.661 41.749L19.6572 42.2062L20.122 38.5643L20.3734 38.5033L23.642 37.7643C24.5639 37.5586 25.4782 38.1376 25.6915 39.0519V39.09H25.6839Z"
        fill="#240872"
      />
      <path
        d="M30.1486 16.9033L22.6362 18.5871L22.8877 16.5757L29.7296 15.0443C30.2477 14.9224 30.7505 15.25 30.8724 15.7681C30.9867 16.2557 30.6896 16.7433 30.2172 16.8881L30.1486 16.9033Z"
        fill="#0E43C1"
      />
      <path
        d="M31.2076 25.9166L25.0209 27.3185L21.4324 28.1185L22.1638 22.3509L22.9714 22.1681L30.0114 20.5833C31.4667 20.2557 32.9219 21.17 33.2571 22.6252V22.6785C33.5848 24.1338 32.6628 25.589 31.2076 25.9166Z"
        fill="#540C00"
      />
      <path
        d="M61.1979 17.4474L59.1179 33.9579L56.2988 56.335C56.0855 58.0493 54.5083 59.276 52.7864 59.055L35.9788 56.9369L22.554 55.2531C20.8398 55.0322 19.6207 53.4626 19.834 51.756L20.1845 48.9293L24.7788 12.4417L25.495 6.73503C25.7159 5.02074 27.2855 3.79407 29.0074 4.01503L35.4074 4.81503L53.1064 7.04741H53.1217L61.2055 17.4474H61.1979Z"
        fill="#E4F4F4"
      />
      <path
        d="M61.1659 17.4595L59.0859 33.9623L56.2668 56.3395C56.0687 57.9395 54.6821 59.1128 53.0897 59.0823C52.9754 59.0823 52.8687 59.0747 52.7544 59.0595L35.9468 56.9414L22.5221 55.2576C20.8078 55.0366 19.5887 53.4671 19.8021 51.7604L19.8478 51.3871C30.8878 49.9166 39.063 46.229 45.8211 41.9395C52.5792 37.6576 56.5564 16.8881 56.5564 16.8881L61.1811 17.4671L61.1659 17.4595Z"
        fill="#BADEE2"
      />
      <path
        d="M61.1659 17.4595L59.0859 33.9623L56.2669 56.3395C56.0688 57.9395 54.6821 59.1128 53.0897 59.0823L33.9888 56.69C33.9888 56.69 47.8631 49.2766 52.0002 46.1909C56.145 43.1052 59.1012 17.2004 59.1012 17.2004L61.1659 17.4595Z"
        fill="#80B8BF"
      />
      <path
        d="M54.1361 29.9908L29.644 26.9054C28.7046 26.7871 27.8472 27.4526 27.7289 28.392L27.725 28.4222C27.6067 29.3616 28.2723 30.219 29.2116 30.3373L53.7038 33.4227C54.6431 33.541 55.5005 32.8755 55.6189 31.9361L55.6227 31.9059C55.741 30.9665 55.0754 30.1091 54.1361 29.9908Z"
        fill="#AE53C4"
      />
      <path
        d="M39.7486 29.909V29.9395C39.6191 30.8766 38.7658 31.5471 37.8286 31.4328L33.8363 30.93L29.1886 30.3433C28.2515 30.229 27.581 29.3681 27.6953 28.4233V28.4004C27.8248 27.4481 28.6782 26.7852 29.6153 26.9071L33.7144 27.4252L38.2477 27.9966C39.1848 28.1109 39.8553 28.9719 39.741 29.909H39.7486Z"
        fill="#8131AA"
      />
      <path
        d="M51.4593 24.0423V24.0881C51.2612 25.5662 49.905 26.6252 48.4193 26.4423L42.1183 25.65L34.7812 24.7204C33.2955 24.5376 32.244 23.1814 32.4269 21.6881L32.4421 21.6576C32.625 20.1566 33.9812 19.1052 35.4593 19.3033L41.9279 20.1185L49.0898 21.0252C50.5755 21.2081 51.6269 22.5642 51.444 24.0423H51.4593Z"
        fill="#2DB24F"
      />
      <path
        d="M53.1383 37.0035L28.6462 33.9181C27.7068 33.7997 26.8494 34.4653 26.731 35.4046L26.7272 35.4349C26.6089 36.3742 27.2745 37.2316 28.2138 37.35L52.706 40.4354C53.6453 40.5537 54.5027 39.8881 54.6211 38.9488L54.6249 38.9186C54.7432 37.9792 54.0776 37.1218 53.1383 37.0035Z"
        fill="#AE53C4"
      />
      <path
        d="M38.7735 36.9262V36.9566C38.644 37.8938 37.7906 38.5643 36.8535 38.45L34.0802 38.0995L28.2211 37.3604C27.284 37.2462 26.6135 36.3852 26.7278 35.4404V35.4176C26.8573 34.4728 27.7106 33.8023 28.6478 33.9243L33.9583 34.5947L37.2802 35.0138C38.2173 35.1281 38.8878 35.989 38.7735 36.9262Z"
        fill="#8131AA"
      />
      <path
        d="M39.4974 45.4823C39.025 43.6385 37.8212 42.0538 36.1678 41.109C35.3755 40.6366 34.4764 40.3242 33.5012 40.2023C29.6155 39.7147 26.0574 42.4652 25.5774 46.3509C25.3945 47.7223 25.6307 49.0557 26.1793 50.229C26.6897 51.3261 27.4897 52.2785 28.4802 52.9871C28.5031 53.01 28.5259 53.0328 28.5564 53.0481C29.4555 53.6804 30.545 54.1223 31.7335 54.2823C33.9355 54.5566 36.0307 53.7947 37.5088 52.3776C38.6593 51.2881 39.4516 49.8176 39.665 48.1338C39.7793 47.2195 39.7183 46.3281 39.505 45.4823H39.4974ZM36.0535 50.1376C35.1164 51.2728 33.6307 51.9052 32.0535 51.7071C31.2612 51.6081 30.545 51.3109 29.9507 50.8538C29.9355 50.8461 29.9202 50.8309 29.9126 50.8233C29.4478 50.4881 29.0669 50.0461 28.7697 49.5661C28.2669 48.7204 28.0307 47.7147 28.1602 46.6785C28.4802 44.2023 30.7202 42.4728 33.1888 42.7776C33.7526 42.8461 34.2935 43.029 34.7735 43.2957C36.0078 43.9585 36.8764 45.2004 37.0745 46.61C37.1278 46.9909 37.1355 47.3871 37.0821 47.7909C36.9678 48.6823 36.6097 49.49 36.0612 50.13L36.0535 50.1376Z"
        fill="#E879BE"
      />
      <path
        d="M39.6649 48.1338C39.4516 49.8176 38.6592 51.2881 37.5087 52.3776C36.0306 53.7947 33.9354 54.5566 31.7335 54.2823C30.5449 54.1223 29.4554 53.6881 28.5563 53.0481C28.5259 53.0328 28.503 53.01 28.4802 52.9871C27.4973 52.2709 26.6897 51.3262 26.1792 50.229C27.063 50.0309 27.924 49.8023 28.7697 49.5738C29.0668 50.0538 29.4554 50.4957 29.9125 50.8309C29.9125 50.8385 29.9354 50.8538 29.9506 50.8614C30.5449 51.3185 31.2611 51.6157 32.0535 51.7147C33.623 51.9128 35.1087 51.2728 36.0535 50.1452C36.6021 49.5128 36.9602 48.6976 37.0744 47.8061C37.1278 47.4023 37.1202 47.0061 37.0668 46.6252C37.9049 46.2595 38.7125 45.8862 39.4973 45.49C39.7106 46.3357 39.7716 47.2271 39.6573 48.1414L39.6649 48.1338Z"
        fill="#CC4495"
      />
      <path
        d="M39.6726 48.1338C39.5964 48.7509 39.4364 49.33 39.2231 49.8709C38.606 51.4328 37.4479 52.7052 36.0003 53.4823C35.7031 53.6347 35.3907 53.7795 35.0631 53.8938C34.0269 54.2747 32.8993 54.4119 31.7336 54.2671C30.5374 54.1223 29.4555 53.6804 28.5488 53.0404L29.4783 51.6004L29.9431 50.8538C30.1793 51.029 30.4307 51.1814 30.705 51.3033C30.7355 51.3261 30.7812 51.349 30.8117 51.3566C30.8422 51.3642 30.8726 51.3795 30.9031 51.3947C31.086 51.4709 31.2841 51.5395 31.4745 51.5852C31.665 51.6385 31.8631 51.6766 32.0612 51.6995C33.1203 51.8214 34.1336 51.5776 34.9793 51.0519C35.2536 50.8842 35.5203 50.6861 35.7488 50.4652H35.7564C36.465 49.7719 36.9603 48.8347 37.0822 47.7985C37.1355 47.3871 37.1279 46.9909 37.0822 46.61C36.8841 45.1852 36.0155 43.9585 34.7812 43.288L36.1907 41.1014C37.8364 42.0461 39.0326 43.6233 39.5126 45.4747C39.726 46.3204 39.7869 47.2119 39.6879 48.1261L39.6726 48.1338Z"
        fill="#458CFF"
      />
      <path
        d="M39.6726 48.1337C39.5964 48.7509 39.4364 49.3299 39.2231 49.8709C38.606 51.4328 37.4479 52.7052 36.0003 53.4823C35.7031 53.6347 35.3907 53.7795 35.0631 53.8938C34.0269 54.2747 32.8993 54.4118 31.7336 54.2671C30.5374 54.1223 29.4555 53.6804 28.5488 53.0404L29.4783 51.6004L29.9431 50.8538C30.1793 51.029 30.4307 51.1814 30.705 51.3033C30.7355 51.3261 30.7812 51.349 30.8117 51.3566C30.8422 51.3642 30.8726 51.3795 30.9031 51.3947C31.086 51.4709 31.2841 51.5395 31.4745 51.5852C31.665 51.6385 31.8631 51.6766 32.0612 51.6995C33.1203 51.8214 34.1336 51.5776 34.9793 51.0518C35.2536 50.8842 35.5203 50.6861 35.7488 50.4652H35.7564C36.465 49.7718 36.9603 48.8347 37.0822 47.7985C37.1355 47.3871 37.1279 46.9909 37.0822 46.6099C37.9203 46.2442 38.7279 45.8709 39.5126 45.4747C39.726 46.3204 39.7869 47.2118 39.6879 48.1261L39.6726 48.1337Z"
        fill="#2560E0"
      />
      <path
        d="M36.0003 53.49C35.7031 53.6423 35.3907 53.7871 35.0631 53.9014C34.0269 54.2823 32.8993 54.4195 31.7336 54.2747C30.5374 54.13 29.4555 53.6881 28.5488 53.0481L29.9431 50.8614C30.1793 51.0366 30.4307 51.189 30.705 51.3109C30.7736 51.3414 30.8345 51.3719 30.9031 51.4023C31.086 51.4785 31.2841 51.5471 31.4745 51.5928C31.665 51.6462 31.8631 51.6842 32.0612 51.7071C33.1203 51.829 34.1336 51.5852 34.9793 51.0595L36.0003 53.4747V53.49Z"
        fill="#0E43C1"
      />
      <path
        d="M51.8249 46.3204C51.2459 46.7319 50.5144 47.2271 49.6763 47.7604L46.1259 47.3109L43.6192 46.9909C43.1011 46.9223 42.7201 46.4423 42.7963 45.9166C42.8649 45.4062 43.3068 45.0557 43.7944 45.0785H43.863L48.7621 45.6881L51.2382 46.0081C51.4744 46.0462 51.6801 46.1528 51.8249 46.3204Z"
        fill="#2560E0"
      />
      <path
        d="M51.5507 16.7814C51.4897 17.2766 51.0478 17.6347 50.5526 17.6119H50.484L39.3602 16.2023L34.9792 15.6538C34.4535 15.5852 34.0802 15.1204 34.1487 14.5871C34.2097 14.0766 34.6516 13.7262 35.1545 13.749H35.223L39.2992 14.2671L50.7202 15.7071C51.2459 15.7757 51.6192 16.2557 51.5507 16.7814Z"
        fill="#2560E0"
      />
      <path
        d="M48.8534 52.088C48.8001 52.5757 48.381 52.9338 47.901 52.9338C47.863 52.9338 47.8249 52.9338 47.7868 52.9261L42.9487 52.3166C42.7353 52.2938 42.5449 52.1947 42.4077 52.0576C43.223 51.5852 44.0687 51.1052 44.9068 50.6252L48.0306 51.0214C48.5563 51.0899 48.9296 51.5623 48.8534 52.088Z"
        fill="#0E43C1"
      />
      <path
        d="M61.1658 17.4519L55.0401 16.6976C53.3182 16.4766 52.0991 14.8995 52.3049 13.1776L53.082 7.05188L61.1658 17.4519Z"
        fill="#2560E0"
      />
      <path
        d="M61.1658 17.3985L59.0858 33.9014L56.2667 56.2785C56.0686 57.8785 54.682 59.0519 53.0896 59.0214L45.3105 58.0462C47.9239 57.9852 52.861 52.0043 54.5982 50.2214C56.3429 48.4233 60.0229 17.2538 60.0229 17.2538L61.1658 17.3985Z"
        fill="#6D91AB"
      />
      <path
        d="M35.3602 4.81188C32.6097 4.97188 24.7393 12.4385 24.7393 12.4385L25.4554 6.73188C25.6764 5.0176 27.2459 3.79093 28.9678 4.01188L35.3602 4.81188Z"
        fill="#CAF5FF"
      />
      <path
        d="M33.4858 9.36044C33.2801 10.9757 31.8096 12.1185 30.1867 11.9128C28.9677 11.7604 28.0153 10.8842 27.7105 9.77187C27.6115 9.41377 27.581 9.0252 27.6267 8.62139C27.8325 7.00615 29.3029 5.85568 30.9182 6.05377C31.3144 6.09949 31.6801 6.22901 32.0001 6.41187C33.0134 6.9833 33.6305 8.12615 33.4782 9.3452L33.4858 9.36044Z"
        fill="#002066"
      />
      <path
        d="M32.0079 6.42712C30.5755 7.40235 29.0212 8.66712 27.7184 9.7795C27.6193 9.4214 27.5888 9.03283 27.6346 8.62902C27.8403 7.01378 29.3107 5.86331 30.926 6.0614C31.3222 6.10712 31.6879 6.23664 32.0079 6.4195V6.42712Z"
        fill="#00588E"
      />
      <path
        d="M30.3088 11.029C30.1793 11.0138 30.0726 10.9528 29.9888 10.8462C29.905 10.7395 29.8745 10.6252 29.8898 10.4957C29.905 10.3662 29.966 10.2595 30.065 10.1833C30.1717 10.1071 30.286 10.069 30.4155 10.0843C30.545 10.0995 30.644 10.1604 30.7279 10.2595C30.8117 10.3662 30.8421 10.4804 30.8269 10.61C30.8117 10.7395 30.7507 10.8462 30.644 10.93C30.5374 11.0138 30.4231 11.0443 30.2936 11.029H30.3088ZM31.2536 7.00616L30.8269 9.81759L30.1031 9.72616L30.3774 6.89188L31.2536 6.99854V7.00616Z"
        fill="white"
      />
      <path
        d="M44.9068 50.6176C44.0764 51.0976 43.2306 51.5776 42.4078 52.05C42.2021 51.8519 42.0802 51.5471 42.1183 51.2424C42.1868 50.709 42.6668 50.3357 43.1926 50.4043L44.8992 50.6176H44.9068Z"
        fill="#2560E0"
      />
      <path
        d="M52.0687 47.0824C52.0077 47.5928 51.5582 47.9433 51.0706 47.9205H51.002L49.6763 47.7605C50.522 47.2271 51.2534 46.7395 51.8248 46.3205C52.0077 46.5186 52.0991 46.7928 52.0687 47.09V47.0824Z"
        fill="#0E43C1"
      />
      <path
        d="M59.6267 18.2214V18.2595L59.5886 18.2138L59.6267 18.2214Z"
        fill="#0E43C1"
      />
    </svg>
  )
}
