import { useRouter } from 'next/navigation'
import { useEffect } from 'react'

import { useIsActiveFF } from '@/components/WhitelistFfContext'

import { isProduction } from './env'
import { FLAG_KEY_DAVI_1163 } from './toggle'

export const useProductionRedirection = () => {
  const { replace } = useRouter()

  const { isActive: isFF_DAVI_1163 } = useIsActiveFF({
    code: FLAG_KEY_DAVI_1163,
    envVal: process.env.NEXT_PUBLIC_FEATURE_DAVI_1163,
  })

  useEffect(() => {
    if (isProduction && isFF_DAVI_1163) {
      if (typeof window !== 'undefined') {
        const currentOriginUrl = new URL(window.location.href)
        if (
          currentOriginUrl &&
          currentOriginUrl.origin === 'https://dashboard.data.kemdikbud.go.id'
        ) {
          replace('/domain-dialihkan')
        } else if (
          currentOriginUrl &&
          currentOriginUrl.origin === 'https://data-portal.belajar.id'
        ) {
          replace('/domain-dialihkan')
        } else if (
          currentOriginUrl &&
          currentOriginUrl.origin === 'https://statistik.data.kemdikbud.go.id'
        ) {
          replace('/domain-dialihkan?target=/dataset')
        } else if (
          currentOriginUrl &&
          currentOriginUrl.origin === 'https://publikasi.data.kemdikbud.go.id'
        ) {
          replace('/domain-dialihkan?target=/publikasi')
        }
      }
    }
  }, [isFF_DAVI_1163, replace])
}
